import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, styled, CircularProgress } from '@mui/material';
import { Google, Facebook } from '@mui/icons-material';
import { createUser } from '../api/services/User';
import FirebaseService from '../auth/Firebase';
import { UserCredential, getAdditionalUserInfo, AdditionalUserInfo } from 'firebase/auth';
import { Link } from 'react-router-dom';
import LogoName from '../assets/LogoName.png'; // Import the logo image

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [isSignUpButtonEnabled, setIsSignUpButtonEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        updateSignUpButtonEnableStatus();
    }, [email, preferredName, password]);

    const handleSignUp = async () => {
        if (loading) {
            return;
        }
        setLoading(true); // Start loading when sign-up starts
        const isEmailVerified = "false";
        const provider = "EMAIL";
        try {
            const userResponse = await createUser.signupUser(email, preferredName, password, isEmailVerified, provider, "");
            setIsErrorMessage(false);
            setMessage("Account created successfully. Check your email to verify your account.");
        } catch (error) {
            setIsErrorMessage(true);
            setMessage(error + "");
        }
        setLoading(false); // Stop loading after sign-up is complete
    };

    const handleGoogleSignUp = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithGoogle();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result);
            if (additionalUserInfo?.isNewUser) {
                const currentUser = await FirebaseService.getCurrentUser();
                if (currentUser && currentUser.email && currentUser.displayName) {
                    saveOAuthUser(currentUser.email, currentUser.displayName, currentUser.emailVerified, currentUser.uid, "GOOGLE");
                }
            } else {
                setMessage("You already have an account. Please sign in.");
            }
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to sign up with Google.");
        }
    };

    const handleFacebookSignUp = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithFacebook();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result);
            if (additionalUserInfo?.isNewUser) {
                const currentUser = await FirebaseService.getCurrentUser();
                if (currentUser && currentUser.email && currentUser.displayName) {
                    saveOAuthUser(currentUser.email, currentUser.displayName, currentUser.emailVerified, currentUser.uid, "FACEBOOK");
                }
            } else {
                setMessage("You already have an account. Please sign in.");
            }
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to sign up with Facebook.");
        }
    };

    const saveOAuthUser = async (currentUserEmail: string, currentUserDisplayName: string, emailVerified: boolean, uid: string, provider: string) => {
        const userResponse = await createUser.signupUser(currentUserEmail, currentUserDisplayName, "", String(emailVerified), provider, uid);
        if (userResponse.error) {
            setIsErrorMessage(true);
            setMessage(`Error signing up user with ${provider}.`);
        } else {
            window.location.href = BASE_APP_URL + "/";
        }
    };

    const updateSignUpButtonEnableStatus = () => {
        setIsSignUpButtonEnabled(email.length > 0 && password.length > 5 && preferredName.length > 0);
    };

    const OAuthButton = styled(Button)({
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            background: '#f1f1f1',
        },
    });

    const Icon = styled('span')({
        marginRight: '8px',
    });

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx={{
                background: 'radial-gradient(circle farthest-corner at 50% 50%, #000000 15.0634765625%, #0091E1 86.2047697368421%)'
            }}
        >
            <Box textAlign="center">
                <img src={LogoName} alt="Logo" style={{ marginBottom: '20px', width: '300px', height: 'auto' }} />
                <Box
                    p={4}
                    bgcolor="white"
                    borderRadius={4}
                    boxShadow={3}
                    maxWidth={400}
                    width="100%"
                    textAlign="center"
                    margin="0 auto"
                >
                    <TextField
                        fullWidth
                        label="Email"
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Preferred Name"
                        margin="normal"
                        value={preferredName}
                        onChange={(e) => setPreferredName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {message && (
                        <Typography
                            color={isErrorMessage ? 'error' : 'primary'}
                            variant="body2"
                            sx={{ mt: 1 }}
                        >
                            {message}
                        </Typography>
                    )}
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={!isSignUpButtonEnabled}
                        onClick={handleSignUp}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
                    </Button>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Or
                    </Typography>
                    <OAuthButton
                        fullWidth
                        onClick={handleGoogleSignUp}
                        startIcon={<Icon><Google /></Icon>}
                    >
                        Sign Up with Google
                    </OAuthButton>
                    <OAuthButton
                        fullWidth
                        onClick={handleFacebookSignUp}
                        startIcon={<Icon><Facebook /></Icon>}
                        sx={{ mt: 2 }}
                    >
                        Sign Up with Facebook
                    </OAuthButton>
                    <Link to="/signin">
                        <Typography variant="body2" color="textSecondary" marginTop="16px">
                            Already have an account? Sign in instead
                        </Typography>
                    </Link>
                    <Typography variant="body2" color="textSecondary" marginTop="26px">
                        By Signing up, you are agreeing to our <a href="https://rewrite.ly/terms/terms-and-conditions" target='_blank'>terms and conditions</a> and <a href="https://rewrite.ly/terms/privacy-policy" target='_blank'>privacy policy</a>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SignUp;