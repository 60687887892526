import React, { useState } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import { Google, Facebook } from '@mui/icons-material'; // Import Facebook icon
import FirebaseService from '../auth/Firebase'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import { GetDetailedUserInfoResponse, createUser, getDetailedUserInfo } from '../api/services/User';
import LogoName from '../assets/LogoName.png'; // Import the logo image

// Styled components for Google and Facebook SignIn buttons
const OAuthButton = styled(Button)({
    background: 'white',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
        background: '#f1f1f1',
    },
});

// Styled component for icons
const IconWrapper = styled('span')({
    marginRight: '8px',
});

// Base app URL from environment variables
const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);

    const handleSignIn = async () => {
        try {
            await FirebaseService.signInWithEmailAndPassword(email, password);
            window.location.href = '/'; // Redirect after sign-in
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Error signing in. Please check your credentials.");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDisplayName: string, emailVerified: boolean, uid: string) => {
        const userResponse = await createUser.signupUser(currentUserEmail, currentUserDisplayName, "", String(emailVerified), "GOOGLE", uid);
        if (userResponse.error) {
            setIsErrorMessage(true);
            setMessage("Error signing up user with Google.");
        } else {
            window.location.href = BASE_APP_URL + "/";
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const userCredential = await FirebaseService.signInWithGoogle();
            const { user } = userCredential;

            if (user) {
                const { email, displayName, emailVerified, uid } = user;

                const existingUser: GetDetailedUserInfoResponse = await getDetailedUserInfo.userResponse();

                if (!existingUser.userInfo?.email) {
                    await saveGoogleSignedUpUser(email!, displayName || "", emailVerified, uid);
                }

                window.location.href = BASE_APP_URL + "/";
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            setIsErrorMessage(true);
            setMessage("Unable to sign in with Google.");
        }
    };

    const handleFacebookSignIn = async () => {
        try {
            const userCredential = await FirebaseService.signInWithFacebook();
            const { user } = userCredential;

            if (user) {
                const { email, displayName, emailVerified, uid } = user;

                const existingUser: GetDetailedUserInfoResponse = await getDetailedUserInfo.userResponse();

                if (!existingUser.userInfo?.email) {
                    await saveGoogleSignedUpUser(email!, displayName || "", emailVerified, uid);
                }

                window.location.href = BASE_APP_URL + "/";
            }
        } catch (error) {
            console.error("Error during Facebook sign-in:", error);
            setIsErrorMessage(true);
            setMessage("Unable to sign in with Facebook.");
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            padding="20px"
            sx={{
                background: 'radial-gradient(circle farthest-corner at 50% 50%, #000000 15.0634765625%, #0091E1 86.2047697368421%)'
            }}
        >
            {/* Logo */}
            <img src={LogoName} alt="Logo" style={{ marginBottom: '50px', width: '300px', height: 'auto' }} />

            <Box
                p={4}
                bgcolor="white"
                borderRadius={4}
                boxShadow={3}
                maxWidth={400}
                width="100%"
                textAlign="center"
            >
                <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleSignIn}
                    disabled={!email || !password}
                >
                    Sign In
                </Button>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Or
                </Typography>
                <OAuthButton
                    fullWidth
                    onClick={handleGoogleSignIn}
                    startIcon={<IconWrapper><Google /></IconWrapper>}
                >
                    Sign In with Google
                </OAuthButton>
                <OAuthButton
                    fullWidth
                    onClick={handleFacebookSignIn}
                    startIcon={<IconWrapper><Facebook /></IconWrapper>}
                    sx={{ mt: 2 }}
                >
                    Sign In with Facebook
                </OAuthButton>
                <Link to="/signup">
                    <Typography variant="body2" color="textSecondary" marginTop="16px">
                        Don't have an account? Create an account.
                    </Typography>
                </Link>
                {isErrorMessage && (
                    <Typography color="error" variant="body2">
                        {message}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default SignIn;