import React from 'react';
import { Modal, Button, Card, Row, Col, message } from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import { UserTier, upgradeProUserToElite, upgradeUser } from '../api/services/User';

interface UpgradeModalProps {
  visible: boolean;
  onCancel: () => void;
  onComplete: () => void;
  proUpgradeLink: string;
  eliteUpgradeLink: string;
  currentUserTier: UserTier;
}

const handleEliteUpgrade = async (eliteUpgradeLink: string, currentUserTier: UserTier, onComplete: any) => {
  if (currentUserTier === UserTier.ELITE_TIER) {
    return;
  }
  if (currentUserTier === UserTier.PRO_TIER) {
    // If upgrading from pro to elite, just need to call upgrade endpoint. This will update the existing subscription to the new price id.
    try {
      await upgradeProUserToElite.call();
      message.success("Subscription upgraded to Elite tier successfully");
    } catch (error) {
      message.error("Could not upgrade your subscription. Please contact support");
    }
    onComplete();
  } else {
    // If directly going for elite, redirect to elite upgrade link
    window.location.href = eliteUpgradeLink;
  }
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ visible, onCancel, onComplete, proUpgradeLink, eliteUpgradeLink, currentUserTier }) => {
  return (
    <Modal
      title="Upgrade Your Account"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Row gutter={[16, 16]}>
        {/* Pro Tier */}
        {proUpgradeLink && (
          <Col xs={24} md={12}>
            <Card
              title="Pro"
              bordered={true}
              extra={<DollarOutlined style={{ fontSize: '24px', color: '#4CAF50' }} />}
            >
              <h3>$4.99 / month</h3>
              <ul>
                <li>2000 rewrites per month</li>
                <li>2000 word limit per rewrite</li>
              </ul>
              <Button type="primary" href={proUpgradeLink} block>
                Upgrade to Pro
              </Button>
            </Card>
          </Col>)}

        {/* Elite Tier */}
        <Col xs={24} md={12}>
          <Card
            title="Elite"
            bordered={true}
            extra={<DollarOutlined style={{ fontSize: '24px', color: '#FF5722' }} />}
          >
            <h3>$9.99 / month</h3>
            <ul>
              <li>10,000 rewrites per month</li>
              <li>5000 word limit per rewrite</li>
            </ul>
            <Button type="primary" onClick={() => handleEliteUpgrade(eliteUpgradeLink, currentUserTier, onComplete)} block>
              Upgrade to Elite
            </Button>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpgradeModal;