import client from "../client";
import FirebaseService from "../../auth/Firebase";
import LocalStorageManager from "../../common/LocalStorageManager";
import axios from 'axios';

interface CreateUserRequest {
    signupUser: (
        email: string,
        preferredName: string,
        password: string,
        emailVerified: string,
        provider: string,
        idamId: string,
    ) => Promise<any>;
}
export interface CreateUserResponse {

}

enum EmailVerificationStatus {
    UNKNOWN_EMAIL_VERIFICATION_STATUS = "UNKNOWN_EMAIL_VERIFICATION_STATUS",
    EMAIL_VERIFIED = "EMAIL_VERIFIED",
    EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED"
}

export interface UserInfo {
    userId: string;
    email: string;
    name: string;
    apiKey: string;
}


export interface AccountInfo {
    userId?: string;
    tier?: UserTier;
    remainingFreeTrialDays?: number;
    remainingRewrites?: number;
    quotaRefreshTimeMillis?: number;
}

export enum UserTier {
    UNKNOWN_USER_TIER = "UNKNOWN_USER_TIER",
    FREE_TIER = "FREE_TIER",
    PRO_TIER = "PRO_TIER",
    ELITE_TIER = "ELITE_TIER"
}

export interface GetAccountInfoResponse {
    accountInfo: AccountInfo;
}

export interface GetDetailedUserInfoResponse {
    emailVerificationStatus: EmailVerificationStatus;
    userInfo: UserInfo;
}

export interface GetPaymentMethodSetupUrlRequest {
}

export interface GetPaymentMethodSetupUrlResponse {
    proPaymentMethodSetupUrl: string;
    elitePaymentMethodSetupUrl: string;
}

export interface ListPromptsResponse {
    prompts: Prompt[];
}

export interface Prompt {
    title: string;
    description: string;
    group: string;
    id?: number;
    promptSource?: PromptSource;
    status?: PromptStatus;
    visibility?: PromptVisibility;
    inspiredByPromptId?: number;
}

export enum PromptSource {
    UNKNOWN_PROMPT_SOURCE = "UNKNOWN_PROMPT_SOURCE",
    SYSTEM = "SYSTEM",
    USER_GENERATED = "USER_GENERATED"
}

export enum PromptStatus {
    UNKNOWN_PROMPT_STATUS = "UNKNOWN_PROMPT_STATUS",
    ACTIVE_PROMPT = "ACTIVE_PROMPT",
    DELETED_PROMPT = "DELETED_PROMPT"
}

export enum PromptVisibility {
    UNKNOWN_PROMPT_VISIBILITY = "UNKNOWN_PROMPT_VISIBILITY",
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE"
}

export interface UpsertPromptRequest {
    prompt?: Prompt;
}

export interface UpsertPromptResponse {
    id?: number;
}

export interface ListPromptGroupsResponse {
    promptGroups: PromptGroupWithStats[];
}

export interface PromptGroupWithStats {
    groupName: string;
    promptCount?: number;
    aggregateCopyCount?: number;
    aggregatedUsageCount?: number;
}

export enum SortOrder {
    UNKNOWN_SORT_ORDER = "UNKNOWN_SORT_ORDER",
    CREATION_DATE_SORT_ORDER = "CREATION_DATE_SORT_ORDER",
    POPULARITY_SORT_ORDER = "POPULARITY_SORT_ORDER"
}

export interface ListPublicPromptsWithStatsRequest {
    group?: string;
    sortOrder?: SortOrder;
}

export interface ListPublicPromptsWithStatsResponse {
    prompts: PromptWithStats[];
}

export interface PromptWithStats {
    title: string;
    description: string;
    group: string;
    creatorUserDisplayName: string;
    id: number;
    inspiredByPromptId: number;
    copyCount: number;
    usageCount: number;
    creationTimestampMillis: number;
}

export const getDetailedUserInfo = {
    userResponse: async (): Promise<GetDetailedUserInfoResponse> => {
        try {
            let response = await client.post("/user/get_detailed_user_info", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const getAccountInfo = {
    call: async (): Promise<GetAccountInfoResponse> => {
        try {
            let response = await client.post("/user/get_account_info", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};


export const cancelSubscription = {
    call: async (): Promise<GetAccountInfoResponse> => {
        try {
            let response = await client.post("/user/cancel_subscription", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const getPaymentMethodSetupUrl = {
    call: async (): Promise<GetPaymentMethodSetupUrlResponse> => {
        try {
            let response = await client.post("/user/get_payment_method_setup_url", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const listPrompts = {
    call: async (): Promise<ListPromptsResponse> => {
        try {
            let response = await client.post("/user/list_prompts", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const upsertPrompt = {
    call: async (prompt: Prompt): Promise<UpsertPromptResponse> => {
        try {
            let response = await client.post("/user/upsert_prompt", { prompt: prompt });
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const verifyUserEmail = {
    verify: async (userId: String, token: String) => {
        try {
            let response = await client.post("/user/verify_user_email", { "user_id": userId, "token": token });
            return response.data;
        } catch (error) {
            return { error: error };
        }
    },
};

export const upgradeUser = {
    call: async (sessionId: String) => {
        try {
            let response = await client.post("/user/upgrade_user", { "checkout_session_id": sessionId });
            return response.data;
        } catch (error) {
            return { error: error };
        }
    },
};

export const listPromptGroups = {
    call: async (): Promise<ListPromptGroupsResponse> => {
        try {
            let response = await client.post("/prompts/list_prompt_groups", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};


export const listPublicPromptsWithStats = {
    call: async (req: ListPublicPromptsWithStatsRequest): Promise<ListPublicPromptsWithStatsResponse> => {
        try {
            let response = await client.post("/prompts/list_public_prompts_with_stats", req);
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const upgradeProUserToElite = {
    call: async () => {
        try {
            let response = await client.post("/user/upgrade_pro_user_to_elite", {});
            return response.data;
        } catch (error) {
            return Promise.reject(error); // Reject the promise with the error
        }
    },
};

export const createUser: CreateUserRequest = {
    signupUser: async (
        email,
        preferredName,
        password,
        emailVerified,
        provider,
        idamId,
    ) => {
        try {
            let response = await client.post("/user/create_user", {
                email: email,
                name: preferredName,
                password: password,
                email_verified: emailVerified,
                provider: provider,
                idam_id: idamId,
            });

            return response;
        } catch (error: any) {
            console.log("Error",error);
            // Check if the error response contains the "Failure-Reason" header
            const failureReason = error?.response?.headers['failure-reason'];
            if (failureReason) {
                return Promise.reject(failureReason); // Reject with the failure reason
            } else {
                return Promise.reject(error.message || "An unknown error occurred");
            }
        }
    },
};

export const storeUserInfoInLocalStorage = async () => {
    try {
        const token = await FirebaseService.getToken();
        const userData = await getDetailedUserInfo.userResponse();
        LocalStorageManager.setDetailedUserInfo(JSON.stringify(userData));
        return localStorage.getItem("user");
    } catch (error) {
        return Promise.reject(error); // Reject the promise with the error
    }
};
